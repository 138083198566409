<template>
    <section class='footer'>
        <div class="row footer-row">
            <div class="footer-left">
                <img src="@/assets/footer/footer-logo.png" alt="footer" class="logo-footer">
            </div>
            <div class="footer-right">
                <div class="footer-right-item">
                    <div>Build</div>
                    <a href="https://docs.exsat.network" target="_blank">Docs</a>
                    <a href="https://scan.exsat.network" target="_blank">Explore</a>
                    <a href="https://faucet.exsat.network" target="_blank">Faucet</a>
                    <a href="https://github.com/exsat-network" target="_blank">Github</a>
                </div>
                <div class="footer-right-item">
                    <div>About</div>
                    <!-- <a href="https://exsat.network/resources/whitepaper.pdf" target="_blank">Whitepaper</a> -->
    
                    <a href="https://docs.exsat.network/terms-and-conditions/privacy-policy" target="_blank">Privacy
                        Policy</a>
                    <a href="https://docs.exsat.network/terms-and-conditions/terms-of-service" target="_blank">Terms of
                        Use</a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXtcwC-P7U-JDuuu_90m9WNBISZYeWs5rSR4gygcg_iZ34_w/viewform?usp=sf_link"
                        target="_blank">Contact Us</a>
                </div>            </div>
        </div>
        <div class="footer-bottom ">
            <div class="footer-bottom-left">
                <div>© 2024 exSat.network. All rights reserved.</div>
            </div>
            <div class="footer-bottom-right">
                <a href="https://x.com/exsatnetwork" target="_blank">
                    <img src="@/assets/footer/x.png" alt="telegram" class="footer-social-icon">
                </a>
                <a href="https://t.me/ExSatNetwork" target="_blank">
                    <img src="@/assets/footer/t.png" alt="telegram" class="footer-social-icon">
                </a>
                <a href="https://medium.com/exsat-network" target="_blank">
                    <img src="@/assets/footer/m.png" alt="telegram" class="footer-social-icon">
                </a>
            </div>
        </div>
    </section>
</template>

<script setup>




</script>

<style lang="scss" scoped>
.footer-row {
    min-width: 1280px;
    justify-content: space-between;

}

.footer {
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 240px;


    .footer-left {
        width: 60%;
    }

    .footer-right {
        width: 20%;
        display: flex;
        justify-content: space-between;
    }

    .footer-right:last-child {
        padding-left: 10px;
    }

    a {
        text-decoration: none;
        display: block;
        margin-top: 8px;
        color: var(--colors-text-text-tertiary-600, #8C8C8C);
        /* Desktop/Body/Caption Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--Colors-Border-border-disabled, #1F1F1F);
        padding-top: 24px;

        .footer-bottom-left {
            color: var(--Colors-Text-text-disabled, #595959);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
        }

        a {
            display: inline-block;
        }

        .footer-social-icon {
            width: 32px;
            height: 32px;
        }
    }
}

.logo-footer {
    width: 320px;
    margin-left: 20px;
}
</style>
