<template>
  <div class="lottie" @mouseover="onHover" @mouseleave="onLeave">
    <Lottie
      :animation-link="props.src"
      :auto-play="false"
      no-margin
      ref="lottie"
      @on-loop-complete="onComplete"
    />
  </div>
</template>

<script setup>
import { Vue3Lottie as Lottie } from 'vue3-lottie'
import { defineProps, useTemplateRef } from 'vue'

let isHovering = false
const lottie = useTemplateRef('lottie')
const props = defineProps({
  src: String,
  hover: {
    type: Boolean,
    default: true
  }
})

function onComplete() {
  if (!isHovering) {
    lottie.value.stop()
  }
}

function onHover(e) {
  console.log('Hover:6666666666')
  lottie.value.play()
  isHovering = true
}

function onLeave() {
  isHovering = false
  console.log('Leave:66666666')
  lottie.value.stop()
}
</script>

<style lang="scss" scoped></style>

