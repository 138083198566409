<template>
  <teleport to="body">
    <transition name="fade">
      <div class="dimmer-phone" @click.self="onDimmerClick" v-if="modalActive && !hideBackdrop"></div>
    </transition>
    <transition name="fade-down">
      <div class="modal-phone" @click.stop v-if="modalActive" :style="modalStyle" id="modal-phone">
        <div class="modal-body">
          <div class="close" @click="cancel" v-if="!hideClose">
            <img src="@/assets/modelClose.png" alt="close">
          </div>
          <slot :ok="ok" :cancel="cancel"></slot>
        </div>
        <div class="modal-footer" v-if="!hideFooter">
          <img src="@/assets/modal/modal-logo.png" alt="modal-logo" class="modal-logo">
        </div>
      </div>
    </transition>


  </teleport>
</template>

<script setup>
import { computed, defineEmits, watch, ref } from 'vue'
import ModalController from './Modal'

const emit = defineEmits(['ok', 'cancel', 'show', 'hide'])
const props = defineProps({
  id: {
    required: true,
    type: String
  },
  title: String,
  hideClose: Boolean,
  hideHeader: Boolean,
  hideFooter: Boolean,
  hideBackdrop: Boolean,
  noBackdropClose: Boolean
})

const modalActive = computed(() => {
  return ModalController.modalStatus[props.id]
})

const scrollPosition = ref(0)
const modalStyle = computed(() => ({
  top: `${Math.max(scrollPosition.value + window.innerHeight * 0.15, 20)}px`,
  position: 'absolute'
}))

watch(() => modalActive.value, (val) => {
  if (val) {
    scrollPosition.value = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollPosition.value}px`
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.width = ''
    document.body.style.overflow = ''
    window.scrollTo(0, scrollPosition.value)
  }
  emit(val ? 'show' : 'hide')
})

function ok() {
  emit('ok')
  ModalController.hide(props.id)
}

function cancel() {
  emit('cancel')
  ModalController.hide(props.id)
}

function onDimmerClick() {
  if (!props.noBackdropClose) {
    cancel()
  }
}
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  @return ($px / 16)+rem;
}

.modal-phone {
  position: fixed;
  width: px2rem(340);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -5%); 
  z-index: 1000;
  display: flex;
  flex-direction: column;
  color: var(--text-body);
  border-radius: px2rem(14);
  background-color: var(--Colors-Background-bg-secondary, #111);
  box-shadow: var(--modal-shadow, 0 32px 64px -12px #10182824);
  overflow: hidden;
  max-height: px2rem(690);

  .close {
    position: absolute;
    right: px2rem(10);
    top: px2rem(10);
    width: px2rem(28);
    height: px2rem(28);
    display: inline-block;
    z-index: 99;

    img {
      width: 100%;
      height: 100%;
    }
    cursor: pointer;
  }

  .modal-logo {
    width: px2rem(82);
    height: px2rem(18);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .modal-body {
    position: relative;
  }

}
.modal-footer {
  padding: px2rem(10) px2rem(24);
}

.dimmer-phone {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);
  z-index: 900;
}





.fade-down-enter-active {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-down-leave-active {
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}


.fade-down-leave-to {
  opacity: 0;
  transform: translate(-50%, 50%);
}
.fade-down-enter-from {
  transform: translate(-50%, 50%);
}

.fade-enter-active{
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(15px);
  transition-property: opacity, backdrop-filter;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(15px);
  transition-property: opacity, backdrop-filter;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  backdrop-filter: blur(0px);
}


</style>
