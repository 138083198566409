import { reactive } from 'vue'

const modalStatus = reactive({})

export default {
  modalStatus,
  show(id) {
    modalStatus[id] = true
  },
  hide(id) {
    modalStatus[id] = false
  }
}
