<template>
  <div class="canvas rive-canvas" v-if="status !== -1">
    <canvas ref="canvas"></canvas>
    <div class="placeholder" :style="placeholderStyle"></div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { Rive } from '@rive-app/canvas'

const canvas = ref(null)
const {src, width, height, options, name} = defineProps({
  src: {
    type: String,
    required: true
  },
  width: String,
  height: String,
  options: {
    type: Object,
    default: () => ({})
  },
  name: {
    type: String,
    default: 'exsat'
  }
})

let rive = null
const status = ref(0)
const placeholderStyle = computed(() => {
  return {
    width: '100%',
    height: height 
  }
})





onMounted(() => {
  status.value = 1
  rive = new Rive({
    src: `/assets/rive/${src}`,
    stateMachines: name,
    canvas: canvas.value,
    autoplay: true,
    onLoad: () => {
      status.value = 2
      rive.resizeDrawingSurfaceToCanvas()
    },
    onLoadError: () => {
      status.value = -1
    },
    ...options
  })
})
</script>

<style lang="scss" scoped>
.canvas {
  width: 100%;
  position: relative;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  scale: 3;
 
}
</style>
