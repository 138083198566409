<template>
  <div 
    ref="element" 
    :class="isVisible ? animateClass : ''"
    :style="animationStyle"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

const props = defineProps({
  threshold: {
    type: Number,
    default: 0.1
  },
  animateClass: {
    type: String,
    default: 'animate__animated animate__fadeInUp'
  },
  duration: {
    type: Number,
    default: 1500
  },
  delay: {
    type: Number,
    default: 0
  },
  once: {
    type: Boolean,
    default: true
  },
  offset: {
    type: String,
    default: '10%' 
  }
});

const element = ref(null);
const isVisible = ref(false);
let observer = null;

const animationStyle = computed(() => ({
  '--animate-duration': `${props.duration}ms`,
  '--animate-delay': `${props.delay}ms`
}));

const onIntersect = (entries) => {
  const entry = entries[0];
  if (entry.isIntersecting) {
    isVisible.value = true;
    if (props.once || !isVisible.value) {
      observer?.unobserve(entry.target);
      observer?.disconnect();
      observer = null;
    }
  } else if (!props.once) {
    isVisible.value = false;
  }
};

watch(isVisible, (newValue) => {
  if (!newValue && observer) {
    observer.disconnect();
    observer = null;
  }
});

onMounted(() => {
  if (!isVisible.value) {
    observer = new IntersectionObserver(onIntersect, {
      threshold: props.threshold,
      rootMargin: `0px 0px ${props.offset} 0px`
    });

    if (element.value) {
      observer.observe(element.value);
    }
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});
</script>

<style scoped>
.animate__animated {
  animation-duration: var(--animate-duration);
  animation-delay: var(--animate-delay);
}

.animate__fadeIn {
  opacity: 0;
}
</style>
