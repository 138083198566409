<template>
    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
</template>

<style scoped>
.dot {
  display: inline-block;
  animation-name: bouncing;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  &:nth-child(2) {
    animation-delay: 125ms;
  }
  &:nth-child(3) {
    animation-delay: 250ms;
  }
}

@keyframes bouncing {
  0% {
    transform: none;
  }
  
  33% {
    transform: translateY(-.5em);
  }
  
  66% {
    transform: none;
  }
}

</style>