import BouncingDots from '@exsat/common/components/BouncingDots.vue'
import Icon from '@exsat/common/components/Icon.vue'
import Skeleton from '@exsat/common/components/Skeleton.vue'

import { createApp } from 'vue'
import { ConfigProvider } from 'ant-design-vue'

import VueGtag from 'vue-gtag'
import App from './App.vue'

const app = createApp(App)
app.component(ConfigProvider.name, ConfigProvider)

app.config.globalProperties.getSvg = function getSvg(name) {
  return new URL(`./assets/${name}.svg`, import.meta.url).href
}

app.component('Ske', Skeleton)
app.component('BouncingDots', BouncingDots)
app.component('Icon', Icon)

app.use(VueGtag, {
  config: { id: 'G-PM1QBCN4QS' }
})

app.mount('#app')
