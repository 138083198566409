<template>
  <div class="scroll-header" :class="{
    'header-hidden': isHidden && !isScrollingUp,
    'header-visible': !isHidden || isScrollingUp
  }">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  threshold: {
    type: Number,
    default: 50
  },
  hideDelay: {
    type: Number,
    default: 300
  },
  showDelay: {
    type: Number,
    default: 100
  }
});

const isHidden = ref(false);
const isScrollingUp = ref(false);
let lastScrollY = window.scrollY;
let hideTimeout = null;
let showTimeout = null;

const handleScroll = () => {
  const currentScrollY = window.scrollY;
  const scrollDelta = currentScrollY - lastScrollY;
  if (hideTimeout) clearTimeout(hideTimeout);
  if (showTimeout) clearTimeout(showTimeout);

  isScrollingUp.value = scrollDelta < 0;

  if (isScrollingUp.value) {
    showTimeout = setTimeout(() => {
      isHidden.value = false;
    }, props.showDelay);
  } else if (currentScrollY > props.threshold) {
    hideTimeout = setTimeout(() => {
      isHidden.value = true;
    }, props.hideDelay);
  }

  lastScrollY = currentScrollY;
};


onMounted(() => {
  lastScrollY = window.scrollY;
  window.addEventListener('scroll', handleScroll, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  if (hideTimeout) clearTimeout(hideTimeout);
  if (showTimeout) clearTimeout(showTimeout);
});
</script>

<style lang="scss" scoped>
.scroll-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
}

.header-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in;
}

.header-visible {
  transform: translateY(0);
  transition: transform 0.5s ease-out;
}
</style>
