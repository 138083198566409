import { BN } from 'bn.js'
import { Asset } from '@wharfkit/antelope'

export function getSvg(name) {
  return new URL(`./assets/${name}.svg`, import.meta.url).href
}
export function getSvg2(name) {
  return new URL(`./assets/nav-phone/${name}.svg`, import.meta.url).href
}

export function convertAddress(source) {
  try {
    return uint64ToAddr(strToUint64(source))
  } catch (err) {
    return err
  }

  function charToSymbol(c) {
    const a = 'a'.charCodeAt(0)
    const z = 'z'.charCodeAt(0)
    const one = '1'.charCodeAt(0)
    const five = '5'.charCodeAt(0)
    const charCode = c.charCodeAt(0)
    if (charCode >= a && charCode <= z) {
      return charCode - a + 6
    }
    if (charCode >= one && charCode <= five) {
      return charCode - one + 1
    }
    if (c === '.') {
      return 0
    }
    throw new Error('invalid address')
  }

  function strToUint64(str) {
    var n = new BN()
    var i = str.length
    if (i >= 13) {
      // Only the first 12 characters can be full-range ([.1-5a-z]).
      i = 12

      // The 13th character must be in the range [.1-5a-j] because it needs to be encoded
      // using only four bits (64_bits - 5_bits_per_char * 12_chars).
      n = new BN(charToSymbol(str[12]))
      if (n >= 16) {
        throw new Error('invalid 13th char')
      }
    }
    // Encode full-range characters.

    while (--i >= 0) {
      n = n.or(new BN(charToSymbol(str[i])).shln(64 - 5 * (i + 1)))
    }
    return n.toString(16, 16)
  }

  function uint64ToAddr(str) {
    return '0xbbbbbbbbbbbbbbbbbbbbbbbb' + str
  }
}

export function displayValue(valueString, decimals) {
  // Logic from web3.js
  if (decimals <= 0) {
    return valueString
  }
  // pad the value with required zeros
  // 13456789 -> 13456789, 1234 -> 001234
  const zeroPaddedValue = valueString.padStart(decimals, '0')
  // get the integer part of value by counting number of zeros from start
  // 13456789 -> '13'
  // 001234 -> ''
  const integer = zeroPaddedValue.slice(0, -decimals)
  // get the fraction part of value by counting number of zeros backward
  // 13456789 -> '456789'
  // 001234 -> '001234'
  const fraction = zeroPaddedValue.slice(-decimals).replace(/\.?0+$/, '')

  if (integer === '') {
    if (fraction === '') {
      return '0'
    }
    return `0.${fraction}`
  }

  if (fraction === '') {
    return integer
  }

  return `${integer}.${fraction}`
}

export function parseInputValue(inputAmount, inputDeciamls) {
  let amount = inputAmount.toString()
  let extraExp = 0
  if (amount.includes('e')) {
    const s = amount.split('e')
    amount = s[0]
    extraExp = Number(s[1])
  }

  const denomination = BigInt(10) ** BigInt(inputDeciamls)
  // From web3.js
  // if value is decimal e.g. 24.56 extract `integer` and `fraction` part
  // to avoid `fraction` to be null use `concat` with empty string
  const [integer, fraction] = amount.split('.').concat('')

  // join the value removing `.` from
  // 24.56 -> 2456
  const value = BigInt(`${integer}${fraction}`)
  // multiply value with denomination
  // 2456 * 1000000 -> 2456000000
  const updatedValue = value * denomination

  let result = updatedValue.toString()
  if (extraExp > 0) {
    result = result.padEnd(result.length + extraExp, '0')
  } else if (extraExp < 0) {
    result = result.slice(0, extraExp).padStart(1, '0')
  }

  if (fraction.length === 0) {
    return result.toString()
  }

  return result.slice(0, -fraction.length).padStart(1, '0')
}

export async function fetchApi(url, options) {
  if (/^\//.test(url)) {
    url = (localStorage.exsatApiBase || location.origin) + url
  }
  url = new URL(url)
  if (options?.query) {
    for (const [k, v] of Object.entries(options.query)) {
      url.searchParams.set(k, v)
    }
  }
  const response = await fetch(url)
  const result = await response.json()
  return result?.data ?? result
}

export function addAsset(a, b) {
  const total = Number(a.units) + Number(b.units)
  return new Asset(total || 0, a.symbol)
}

export function displayAsset(a) {
  return displayValue(a.units.toString(), a.symbol.precision) + ' ' + a.symbol.name
}

export function bytesToHex(bytes) {
  return '0x' + Array.from(bytes, (byte) => byte.toString(16).padStart(2, '0')).join('')
}

export function setRootFontSize() {
  const width = window.innerWidth
  let fontSize = (width / 375) * 16 // 假设设计稿宽度为 375px
  document.documentElement.style.fontSize = `${fontSize}px`
  console.log(fontSize, 'fontSize')
}

export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

