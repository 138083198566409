<template>
    <section class='footer-phone'>
        <div class=" footer-row">
            <div class="logo-footer">
                <img src="@/assets/footer-logo-phone.png" alt="footer">
            </div>
            <div class="footer-desc">
                Extending Satoshi's Vision, Unlocking Bitcoin's Possibilities
            </div>
            <div class="footer-links">
                <div class="footer-links-item">
                    <div>Build</div>
                    <a href="https://docs.exsat.network" target="_blank">Docs</a>
                    <a href="https://scan.exsat.network" target="_blank">Explore</a>
                    <a href="https://faucet.exsat.network" target="_blank">Faucet</a>
                    <a href="https://github.com/exsat-network" target="_blank">Github</a>
                </div>
                <div class="footer-links-item">
                    <div>About</div>
                    <!-- <a href="https://exsat.network/resources/whitepaper.pdf" target="_blank">Whitepaper</a> -->

                    <a href="https://docs.exsat.network/terms-and-conditions/privacy-policy" target="_blank">Privacy
                        Policy</a>
                    <a href="https://docs.exsat.network/terms-and-conditions/terms-of-service" target="_blank">Terms of
                        Use</a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXtcwC-P7U-JDuuu_90m9WNBISZYeWs5rSR4gygcg_iZ34_w/viewform?usp=sf_link"
                        target="_blank">Contact Us</a>
                </div>
            </div>
        </div>
        <div class="footer-bottom-phone">
            <div class="footer-bottom-left">
                <div>© 2024 exSat.network. All rights reserved.</div>
            </div>
            <div class="footer-bottom-right">
                <a href="https://x.com/exsatnetwork" target="_blank">
                    <img src="@/assets/footer/x.png" alt="telegram" class="footer-social-icon">
                </a>
                <a href="https://t.me/ExSatNetwork" target="_blank">
                    <img src="@/assets/footer/t.png" alt="telegram" class="footer-social-icon">
                </a>
                <a href="https://medium.com/exsat-network" target="_blank">
                    <img src="@/assets/footer/m.png" alt="telegram" class="footer-social-icon">
                </a>
            </div>
        </div>
    </section>
</template>

<script setup>




</script>

<style lang="scss" scoped>
@function px2rem($px) {
    @return ($px / 16)+rem;
}

.footer-phone {
    margin: px2rem(40) auto 0;

    .footer-row {
        .logo-footer {
            width: 100%;
            text-align: center;
            margin-bottom: px2rem(10);
            img {
                width: px2rem(88);
            }
        }

        .footer-desc {
            text-align: center;
            color: var(--colors-text-text-tertiary-600, #8C8C8C);
            text-align: center;
            /* Mobile/Body/Caption Regular */
            font-family: Inter;
            font-size: px2rem(12);
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            width: px2rem(270);
            margin: px2rem(10) auto 0;
            margin-bottom: px2rem(20);
        }

        .footer-links {
            display: flex;
            justify-content: space-between;

            .footer-links-item {
                flex: 1;
                color: var(--colors-text-text-primary-900, #F5F5F5);
                /* Mobile/Body/Caption SemiBold */
                font-family: Inter;
                font-size: px2rem(12);
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                display: flex;
                flex-direction: column;
                gap: px2rem(8);
                padding: px2rem(16);

                /* 14.4px */
                a {
                    color: var(--colors-text-text-tertiary-600, #8C8C8C);
                    /* Mobile/Body/Caption Regular */
                    font-family: Inter;
                    font-size: px2rem(12);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    text-decoration: none;

                    /* 14.4px */
                }
            }
        }

    }

    .footer-bottom-phone {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--Colors-Border-border-disabled, #1F1F1F);
        height: px2rem(72);
        margin: px2rem(16);

        .footer-bottom-left {
            color: var(--Colors-Text-text-disabled, #595959);
            /* Mobile/Body/Footnote Regular */
            font-family: Inter;
            font-size: px2rem(10);
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 12px */
        }

        a {
            display: inline-block;
        }

        .footer-social-icon {
            width: px2rem(24);
            height: px2rem(24);
        }
    }
}
</style>
