<template>
  <img class="icon" :src="getSvg(value)" :style="style"/>
</template>

<script>
export default {
  name: 'icon',
  props: {
    value: String,
    size: String
  },
  computed: {
    style() {
      if (!this.size) {
        return null
      }
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: sub;
}
</style>
