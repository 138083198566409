<template>
    <ScrollHeader :threshold="100" :hide-delay="200" :show-delay="50" v-if="isShowHeader">
        <Header :nav-class="scrollY > 0 ? 'bg-dark' : 'bg-transparent'" />
    </ScrollHeader>
    <First />
    <div class="container">
        <!-- <News /> -->
        <Vision />
        <!-- <Join /> -->
        <Outro />
        <Partners />
        <Engage />
        <Footer/>
    </div>
</template>

<script setup>
import ScrollHeader from '@/components/ScrollHeader.vue';
import Header from '@/components/Header.vue'
import { onMounted, ref, onUnmounted } from 'vue'
import Engage from './Engage.vue'
import First from './First.vue'
import Footer from './Footer.vue'
import Outro from './Outro.vue'
import Partners from './Partners.vue'
import Vision from './Vision.vue'
import Join from './Join.vue'
import News from './News.vue'
const scrollY = ref(0);
const isShowHeader = ref(true)

const handleScroll = () => {
    const modalElement = document.querySelector('#modal-pc')
    isShowHeader.value = !modalElement
    scrollY.value = window.scrollY;
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.container {
    max-width: var(--container-width);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    padding-top: 60px;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    min-width: 1280px;
    @include sm() {
        padding-top: 20px;
    }
}
</style>
