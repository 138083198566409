<template>
  <section>
    <div class="first-phone">
      <rive-canvas src="home.riv" :width="innerWidth" :height="innerWidth / 0.6"  class="first-canvas" />
      <div class="first-content">
        <div class="first-title animate__animated animate__fadeInDown">
          <img src="@/assets/first_ellipse.svg" alt="">
          <span>exSat Mainnet Launched on 2024/10/23 </span>
        </div>
        <div class='animate__animated animate__fadeInUp first-h1'>
          Extending Satoshi's Vision
          <br />
          Unlocking Bitcoin's Possibilities
        </div>
        <div class='animate__animated animate__fadeInUp first-btn'>
          <a href="https://exsat.network/app" class="btn btn-white exsat-app-link" target="_blank">
            BTC Metadata Sync Protocol
            <icon value="external" />
          </a>
          <a href="/app/bridge" class="btn btn-white" target="_blank">
            Bridge
            <icon value="external" />
          </a>
        </div>
      </div>
      <div class="first-bottom">
        <!-- <img src="@/assets/Frame_phone.png" alt="" class="first-bottom-bg-1" /> -->
        <!-- <img src="@/assets/kzt-phone.png" alt="" class="first-bottom-bg-2" /> -->
        <div class="first-bottom-text">
          <div class="exSat-bottom-item">
            <div class="exSat-bottom-item-left">
              <div class="exSat-bottom-item-title">Connected BitCoin Hashrate</div>
              <div class="exSat-bottom-item-value">{{ exSatData.connectedBtcHashrate ?
                exSatData.connectedBtcHashrate : '-' }}</div>
            </div>
            <div class="exSat-bottom-item-right">
              <div class="exSat-bottom-item-title">Total Validators</div>
              <div class="exSat-bottom-item-value">{{ exSatData.totalValidators ?
                formatNumber(exSatData.totalValidators, 0) : '-' }}</div>
            </div>
          </div>
          <div class="exSat-bottom-item">
            <div class="exSat-bottom-item-left">
              <div class="exSat-bottom-item-title">Total BTC Staked</div>
              <div class="exSat-bottom-item-value">{{ !exSatData.totalBtcStaked ? '-' :
                formatNumber(exSatData.totalBtcStaked, 1) + ' BTC' }}</div>
            </div>
            <div class="exSat-bottom-item-right">
              <div class="exSat-bottom-item-title">Total Synchronizers</div>
              <div class="exSat-bottom-item-value">{{ exSatData.totalSynchronizers ?
                formatNumber(exSatData.totalSynchronizers, 0) : '-' }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { fetchApi } from '@/utils.js'
import RiveCanvas from "./RiveCanvas.vue";
const innerWidth = ref(0);
const innerHeight = ref(0);
const handleResize = () => {
  innerWidth.value = window.innerWidth;
  innerHeight.value = window.innerHeight;
  if (window.innerWidth < 1280) {
    innerWidth.value = 1280;
  }


};
const exSatData = ref({})

onMounted(() => {
  handleResize()
  getTableData()
});
async function getTableData() {
  const res = await fetchApi('https://api.exsat.network/v1/misc/statistic')
  exSatData.value = res
}

function formatNumber(num, decimals) {
  return Number(num).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

</script>

<style lang="scss" scoped>
@function px2rem($px) {
  @return ($px / 16)+rem;
}

.first-phone {
  width: 100%;
  height: px2rem(560);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .first-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: px2rem(560);
  }

  .exsat-app-link {
    margin-right: px2rem(5);
    text-decoration: none;
  }


  .first-content {
    width: px2rem(330);
    margin: 0 auto;
    text-align: center;
    font-family: Inter;
    font-size: px2rem(22);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
    background: linear-gradient(251deg, #FFF 37.27%, #999 108.22%);
    background-clip: text;
    -webkit-background-clip: text;
    margin-top: -30%; 

    .first-title {
      color: #FFF;
      font-family: Inter;
      font-size: px2rem(8);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.1px;
      text-transform: uppercase;
      border-radius: 999px;
      border: 1px solid #3A3A3A;
      background: linear-gradient(180deg, #1E1E1E 0%, #141414 100%);
      height: px2rem(24);
      width: px2rem(220);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: px2rem(14);
        height: px2rem(14);
        margin-right: px2rem(4);
      }
      pointer-events: none !important;

    }

    .first-h1 {
      margin-top: px2rem(16);
      pointer-events: none !important;

    }

    .first-btn {
      margin-top: px2rem(32);
      color: var(--Component-colors-Buttons-white-fgColor-default, #1F1F1F);
      font-family: Inter;
      font-size: px2rem(12);
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      display: flex;
      justify-content: center;
      gap: px2rem(5);

    }
  }

  .first-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('@/assets/kzt-phone.png');
    background-size: cover;
    background-position: center;
    height: px2rem(150);
    .first-bottom-bg-1 {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: px2rem(200);
    }

    .first-bottom-bg-2 {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .first-bottom-text {
      width: px2rem(200);
      margin: 0 auto;
      z-index: 10 !important;
      text-align: center;
      position: static;
      z-index: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0;
      position: absolute;
      left: px2rem(-52);
      right: 0;
      bottom: px2rem(20);

      .exSat-bottom-item {
        display: flex;
        justify-content: center;

        .exSat-bottom-item-left {
          text-align: center;
          width: 60%;
        }

        .exSat-bottom-item-right {
          text-align: center;
          width: 40%;
          margin-left: px2rem(-10);
        }

        .exSat-bottom-item-title {
          color: var(--colors-text-text-secondary-700, #BFBFBF);
          text-align: center;
          text-shadow: 0px 0px 1.191px rgba(255, 255, 255, 0.25);
          font-family: "Chakra Petch";
          font-size: px2rem(8);
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 9px */
          letter-spacing: 0.06px;
        }

        .exSat-bottom-item-value {
          color: var(--colors-foreground-fg-brand-primary-600, #FFB029);
          text-align: center;
          text-shadow: 0px 0px 2.085px #F90;
          font-family: "Chakra Petch";
          font-size: px2rem(18);
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }
    }
  }
}
</style>
